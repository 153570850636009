import React from 'react'
import { graphql } from 'gatsby'
import GalleryPage from '../components/GalleryPage'

const PaintingsPage = ({ data }) => (
  <GalleryPage data={data} currentPath="/paintings" title="Paintings" />
)

export default PaintingsPage

export const query = graphql`
  query {
    allPicturesCsv(filter: { type: { eq: "painting" } }) {
      ...Pictures
    }
  }
`
